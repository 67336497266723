<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 "
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container>
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col cols="2" align="right" class="py-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadProcesoDevengamiento()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <v-col cols="6" md="3" class="py-0">
                    <!-- fecha consulta desde -->
                    <v-menu
                      ref="menu"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="pt-2"
                          v-model="fechaDesdeSelected"
                          label="Fecha de proceso desde"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="formato DD/MM/AAAA"
                          @keydown.tab="menu1 = false"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          clearable
                          dense
                          outlined
                          autocomplete="off"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>

                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        scrollable
                        @change="
                          [(fechaDesdeSelected = formatDate(fechaDesde))]
                        "
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" md="3" class="py-0">
                    <!-- fecha consulta hasta -->

                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="pt-2"
                          v-model="fechaHastaSelected"
                          label="Fecha de proceso hasta"
                          :append-icon="calendarIcon"
                          @keydown.tab="menu2 = false"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          hint="formato DD/MM/AAAA"
                          clearable
                          dense
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                          outlined
                          autocomplete="off"
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>

                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        scrollable
                        @change="
                          [(fechaHastaSelected = formatDate(fechaHasta))]
                        "
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" sm="6" md="6" class="py-0">
                    <v-switch
                      v-model="verUser"
                      label="Ver solo procesos del usuario logueado."
                      item-text="value"
                      item-value="id"
                      dense
                      outlined
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Codigo agente -->
                  <v-col cols="4" class="py-0" md="3">
                    <v-text-field
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      outlined
                      dense
                      clearable
                      v-mask="'#########'"
                      label="Código de agente"
                      v-model="codigoAgenteCta"
                      @change="buscarAgente"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" class="py-0" md="4">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      disabled
                      label="Nombre de agente de cuenta"
                      v-model="nombreAgenteCta"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Boton buscar por nombre o dni -->
                  <v-col cols="1" md="1" class="py-0 px-0">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          absolute
                          fab
                          small
                          color="primary"
                          :disabled="searchAgenteBtn"
                          @click="modalBusquedaAgecta = true"
                        >
                          <v-icon>{{ searchIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Buscar agente de cuenta</span>
                    </v-tooltip>
                  </v-col>

                  <!-- estado -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-autocomplete
                      v-model="estadoSelected"
                      :items="estado"
                      label="Estado"
                      item-value="id"
                      item-text="value"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsDevengCoseguros"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="nuevoProcedimientoCoseguro"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    :disabled="item.usuarioAnula.length > 0"
                    v-on="on"
                    @click="verDetalleDevengamiento(item.coseProcId)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver detalle</span>
              </v-tooltip>

              <v-tooltip left v-if="canAnular">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    :disabled="item.usuarioAnula.length > 0"
                    @click="anularProceso(item.coseProcId)"
                  >
                    {{ anuladoIcon }}
                  </v-icon>
                </template>
                <span>Anular proceso</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="modalBusquedaAgecta"
      v-if="modalBusquedaAgecta"
      @keydown.esc="toggleModalBusquedaAgecta"
      max-width="65%"
      persistent
    >
      <BuscarAgenteCuenta
        @toggleModalBusquedaAgecta="toggleModalBusquedaAgecta"
        @findAgenteCta="findAgenteCta"
      ></BuscarAgenteCuenta>
    </v-dialog>
    <v-dialog
      v-if="openModalVerDetalle"
      v-model="openModalVerDetalle"
      max-width="80%"
      @keydown.esc="closeModalVerDetalle"
      persistent
    >
      <VerDetalleDevengCoseguros
        :coseId="coseId"
        @closeModalVerDetalle="closeModalVerDetalle"
      ></VerDetalleDevengCoseguros
    ></v-dialog>
    <DeleteDialog
      :titleProp="titleAnular"
      :maxWidth="'40%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showAnularModal"
      @onDeleteItem="confirmAnular()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import VerDetalleDevengCoseguros from "@/components/modules/cuotas/devengamientos/VerDetalleDevengCoseguros.vue";
import BuscarAgenteCuenta from "@/components/modules/cuotas/agenteCuenta/BuscarAgenteCuenta.vue";
import { mask } from "vue-the-mask";

import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  directives: { mask },
  components: {
    PageHeader,
    DeleteDialog,
    VerDetalleDevengCoseguros,
    FiltersSelected,
    BuscarAgenteCuenta,
    GoBackBtn,
    Ayuda
  },
  name: "DevengamientoCosegurosWeb",
  data: vm => ({
    searchIcon: enums.icons.SEARCH,
    anuladoIcon: enums.icons.CIRCLE_NONE,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    seeIcon: enums.icons.SEE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    optionCode: enums.webSiteOptions.DEVENG_COSEGUROS_WEB,
    rules: rules,
    routeToGo: "ProcesosDevengamientos",
    verUser: 1,
    sortDesc: true,
    anulado: false,
    codigoAgenteCta: null,
    modalBusquedaAgecta: false,
    agente: {},
    searchAgenteBtn: false,
    title: enums.titles.DEVENG_COSEGUROS,
    estadoSelected: 0,
    showHelp: false,
    showIcon: true,
    showExpand: false,
    estado: [
      {
        id: 0,
        value: "Todos"
      },
      {
        id: 1,
        value: "Anulados"
      },
      {
        id: 2,
        value: "No anulados"
      }
    ],
    headers: [
      {
        text: "N° de Proceso",
        value: "coseProcId",
        sortable: false
      },
      {
        text: "Fecha desde",
        value: "fechaDesde",
        sortable: false
      },
      {
        text: "Fecha hasta",
        value: "fechaHasta",
        sortable: false
      },
      {
        text: "Agente de cuenta",
        value: "agectaNom",
        sortable: false
      },
      {
        text: "Usuario",
        value: "usuarioÁlta",
        sortable: false
      },
      {
        text: "Fecha",
        value: "fechaAlta",
        sortable: false
      },
      {
        text: "Usuario anula",
        value: "usuarioAnula",
        sortable: false
      },
      {
        text: "Fecha anula",
        value: "fechaAnula",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    search: "",
    canAnular: false,
    canCreate: false,
    titleAnular: `¿Desea anular el proceso seleccionado?<h3 style="font-size: 16px;">&nbsp Se eliminarán todas las órdenes grabadas en el proceso.<h3>`,
    anularBoton: "Anular",
    showFilters: true,
    isFormValid: true,
    coseId: null,
    menu1: false,
    menu2: false,
    isLoading: false,
    isDisabled: false,
    showAnularModal: false,
    openModalComprobante: false,
    filtersApplied: [],
    itemsDevengCoseguros: [],
    fechaDesde: null,
    fechaHasta: null,
    nombreAgenteCta: null,
    fechaDesdeSelected: null,
    fechaHastaSelected: null,
    openModalVerDetalle: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.getFechas();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getDevengamientosCosegurosCtaCte:
        "configuracion/getDevengamientosCosegurosCtaCte",
      anularProcesoDevengamientoCoseguro:
        "configuracion/anularProcesoDevengamientoCoseguro",
      getAgenteCuentaByCodigo: "afiliaciones/getAgenteCuentaByCodigo",
      setAlert: "user/setAlert"
    }),
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    getFechas() {
      let now = new Date();
      let año = now.getFullYear();
      let mes = now.getMonth() + 1;
      let dia = now.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes;
      let three = new Date(now.setMonth(now.getMonth() - 3));
      let threeAño = three.getFullYear();
      let threeMes = three.getMonth() + 1;
      let threeDia = three.getDate();
      threeDia < 10 ? (threeDia = `0${threeDia}`) : threeDia;
      threeMes < 10 ? (threeMes = `0${threeMes}`) : threeMes;
      this.fechaDesde = `${threeAño}-${threeMes}-${threeDia}`;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
    },

    toggleModalBusquedaAgecta() {
      this.modalBusquedaAgecta = !this.modalBusquedaAgecta;
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_DEVENG_COSEGURO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.ANULAR_PROCESO:
            this.canAnular = true;
            break;
          default:
            break;
        }
      });
    },
    findAgenteCta(agenteSelected) {
      this.codigoAgenteCta = agenteSelected.agenteId.toString();
      this.nombreAgenteCta = agenteSelected.nombre;
    },
    async buscarAgente() {
      if (this.codigoAgenteCta != null) {
        const search = await this.searchAgenteCta(this.codigoAgenteCta);
        this.findAgenteCta(search);
      } else {
        this.nombreAgenteCta = null;
      }
    },
    async searchAgenteCta(codigoAgte) {
      {
        const response = await this.getAgenteCuentaByCodigo({
          codigo: codigoAgte,
          nombre: "",
          dni: "",
          cuit: ""
        });
        const agenteResp = response[0];
        if (agenteResp) {
          return agenteResp;
        }
      }
    },
    async loadProcesoDevengamiento() {
      this.isLoading = true;
      this.showFilters = false;
      this.customizeFiltersApplied();
      const data = {
        agectaId: this.codigoAgenteCta,
        estado: this.estadoSelected,
        verSoloUsuario: this.verUser ? 1 : 0,
        fechaDesde: this.fechaDesdeSelected,
        fechaHasta: this.fechaHastaSelected,
        usuario: ""
      };
      const resp = await this.getDevengamientosCosegurosCtaCte(data);
      this.itemsDevengCoseguros = resp;
      this.isLoading = false;
    },
    anularProceso(id) {
      this.showAnularModal = true;
      this.idToAnular = id;
    },
    async confirmAnular() {
      const response = await this.anularProcesoDevengamientoCoseguro(
        this.idToAnular
      );
      if (response.status === 200) {
        this.showAnularModal = false;
        this.setAlert({
          type: "success",
          message: "El proceso ha sido anulado."
        });
        this.loadProcesoDevengamiento();
      }
    },
    nuevoProcedimientoCoseguro() {
      this.$router.push({ name: "NuevoDevengCoseguro" });
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(1, 0, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
      if (this.verUser) {
        const userLog = "SI";
        this.filtersApplied.splice(2, 0, {
          key: "verSoloUsuario",
          label: "Usuario logueado",
          model: userLog
        });
      } else {
        const userLog = "NO";
        this.filtersApplied.splice(3, 0, {
          key: "verSoloUsuario",
          label: "Usuario logueado",
          model: userLog
        });
      }
      if (this.codigoAgenteCta) {
        this.filtersApplied.splice(4, 0, {
          key: "codigoAgenteCta",
          label: "Código agente cta.",
          model: this.codigoAgenteCta
        });
      }
      if (this.nombreAgenteCta) {
        this.filtersApplied.splice(5, 0, {
          key: "nombreAgenteCta",
          label: "Nombre agente cta.",
          model: this.nombreAgenteCta
        });
      }
      if (this.estadoSelected == 0) {
        const estadoSelec = "Todos";
        this.filtersApplied.splice(6, 0, {
          key: "estado",
          label: "Estado",
          model: estadoSelec
        });
      } else if (this.estadoSelected == 1) {
        const estadoSelec = "Anulados";
        this.filtersApplied.splice(7, 0, {
          key: "estado",
          label: "Estado",
          model: estadoSelec
        });
      } else {
        const estadoSelec = "No anulados";
        this.filtersApplied.splice(8, 0, {
          key: "estado",
          label: "Estado",
          model: estadoSelec
        });
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    verDetalleDevengamiento(coseId) {
      this.coseId = coseId;
      this.openModalVerDetalle = true;
    },
    closeModalVerDetalle() {
      this.openModalVerDetalle = false;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
