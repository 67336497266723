<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Detalle del proceso de devengamiento</v-card-title
      >
      <v-data-table
        :headers="headers"
        :items="detalleProcesoDevengamiento"
        class="elevation-1"
        item-key="rglcId"
        :loading="isLoading"
      >
      </v-data-table>
      <template v-slot:[`item.importe`]="{ item }">
        <span>{{ formatPrice(item.importe) }}</span>
      </template>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="3" class="py-1">
          <v-tooltip left max-width="40%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="to-right fontsize"
                v-bind="attrs"
                outlined
                @click="exportExcelModelo"
              >
                Exportar detalle
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-btn outlined @click="closeModalVerDetalle"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  name: "VerDetalleDevengCoseguros",
  props: {
    coseId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    checkIcon: enums.icons.CHECK_OUTLINE,
    excelIcon: enums.icons.EXPORT_EXCEL,
    isLoading: false,
    detalleProcesoDevengamiento: [],
    headers: [
      {
        text: "Tipo de devengamiento",
        value: "tipoDevengamiento",
        sortable: false
      },
      {
        text: "Nombre de agente de cta.",
        value: "agectaNombre",
        sortable: false
      },
      {
        text: "Concepto / comprobante",
        value: "comprobanteConcepto",
        sortable: false
      },
      {
        text: "Importe",
        value: "importeString",
        sortable: false,
        align: "end"
      },
      { text: "", value: "data-table-expand", align: "end" }
    ],
    allowedActions: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setDetalleProcDevengamiento();
  },
  methods: {
    ...mapActions({
      exportarExcelDevengamientoCoseguro:
        "configuracion/exportarExcelDevengamientoCoseguro"
    }),
    async setDetalleProcDevengamiento() {
      this.isLoading = true;
      const data = await this.exportarExcelDevengamientoCoseguro(this.coseId);
      this.detalleProcesoDevengamiento = data;
      this.isLoading = false;
    },
    formatPrice(importe) {
      const formatPrice = new Intl.NumberFormat("es-AR");
      const importeFormatted = formatPrice.format(importe);
      return importeFormatted;
    },
    closeModalVerDetalle() {
      this.$emit("closeModalVerDetalle");
    },
    exportExcelModelo() {
      let result = [];
      this.detalleProcesoDevengamiento.forEach(x =>
        result.push({
          ["Tipo de devengamiento"]: x.tipoDevengamiento,
          ["Código de agente"]: x.agectaId,
          ["Agente de cuenta."]: x.agectaNombre,
          ["Concepto / comprobante"]: x.comprobanteConcepto,
          ["Importe"]: x.importe
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle del proceso de devengamiento");
    }
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
